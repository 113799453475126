<template>
  <b-card
    title="Orçamentos"
  >
    <b-row class="mb-2">
      <b-col
        md="2"
      >
        <label>Status</label>
        <v-select
          v-model="statusFilter"
          :options="optionsStatus"
          multiple
        />
      </b-col>
      <div class="col-md-2 mb-2">
        <label>Atendente</label>
        <v-select
          v-model="atendenteFilter"
          :options="optionsAtendenteFilter"
          multiple
        />
      </div>
      <b-col
        md="2"
      >
        <label>Tipo de Atendimento</label>
        <v-select
          v-model="tipoAtendimentoFilter"
          :options="optionsTipoAtendimento"
          multiple
        />
      </b-col>
      <b-col
        md="2"
      >
        <label>Dias em Aberto</label>
        <v-select
          multiple
        />
      </b-col>
      <b-col
        md="2"
      >
        <label>Seguradora</label>
        <v-select
          v-model="seguradoraFilter"
          :options="optionsSeguradora"
          multiple
        />
      </b-col>
      <b-col
        md="2"
      >
        <label>Marca</label>
        <v-select
          v-model="marcaFilter"
          :options="optionsMarca"
          multiple
        />
      </b-col>
    </b-row>
    <b-row>
      <!-- modal visualizar info orcamento-->
      <b-modal
        id="modal-detalhe-orcamento"
        ref="showModalOrcamento"
        size="xl"
        :title="`Orcamento ${infoOrcamento.orcamento_id}`"
        @hidden="editavel=false, clearAcoesOrcamento()"
      >
        <b-tabs>
          <b-tab v-if="visualizarDadosModal">
            <template #title>
              <feather-icon icon="FileTextIcon" />
              <span>Orcamento</span>
            </template>

            <b-row class="mb-1">
              <b-col md="2">
                <label><strong>Sinistro</strong></label>
                <b-form-input
                  :value="infoOrcamento.sinistro"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label><strong>Data Abertura</strong></label>
                <b-form-input
                  :value="infoOrcamento.data_abertura ? formatTimezone(infoOrcamento.data_abertura, true) : ''"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>Status</strong></label>
                <b-form-input
                  :value="infoOrcamento.status"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>Valor Total Orcamento</strong></label>
                <!-- <b-form-input
                  :value="infoOrcamento.valor_total_orcamento ? valorBr(infoOrcamento.valor_total_orcamento, true) : ''"
                  disabled
                /> -->
                <money
                  :value="infoOrcamento.valor_total_orcamento ? valorBr(infoOrcamento.valor_total_orcamento, true) : ''"
                  v-bind="money"
                  class="form-control"
                  readonly
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>Valor Aprovado no Sistema</strong></label>
                <b-form-input
                  :value="infoOrcamento.valor_total_aprovado ? valorBr(infoOrcamento.valor_total_aprovado, true) : ''"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>Tipo Orçamento</strong></label>
                <b-form-input
                  :value="infoOrcamento.tipo_orcamento"
                  disabled
                />
              </b-col>
            </b-row>
            <hr>
            <b-row
              v-for="(dados,index) in infoOrcamento.servicos"
              :key="index"
              class="mb-1"
            >
              <b-col md="2">
                <label for=""><strong>Tipo Servico</strong></label>
                <b-form-select
                  v-model="dados.tipo_servico"
                  :disabled="!editavel"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="optionsTipoServico"
                  @change="updateTipoServico(dados.tipo_servico, dados)"
                />
                <!-- <b-form-input
                  v-model.lazy="dados.tipo_servico"
                  :disabled="!editavel"
                  @change="updateTipoServico(dados.tipo_servico, dados)"
                /> -->
              </b-col>
              <b-col md="3">
                <label for=""><strong>Descrição</strong></label>
                <b-form-input
                  :value="dados.descricao"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>Observação</strong></label>
                <b-row>
                  <b-col>
                    <b-form-input
                      v-model.lazy="dados.observacao"
                      :disabled="!editavel"
                      @change="updateObservacao(dados.observacao, dados)"
                    />
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="1">
                <label for=""><strong>Quantidade</strong></label>
                <b-form-input
                  :value="dados.quantidade"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>Valor Solicitado</strong></label>
                <div class="d-flex">
                  <!-- <b-form-input
                    :value="dados.valor_solicitado"
                    disabled
                  /> -->
                  <money
                    :value="dados.valor_solicitado"
                    v-bind="money"
                    class="form-control"
                    readonly
                  />
                </div>
              </b-col>
              <b-col md="2">
                <label for=""><strong>Valor Aprovado</strong></label>
                <b-row>
                  <b-col>
                    <b-form-input
                      v-money="money"
                      :value="dados.valor_aprovado"
                      :disabled="!editavel"
                      @change="updateValorSolicitado(removeCurrencyMask($event), index)"
                    />
                  </b-col>
                  <feather-icon
                    v-show="infoOrcamento.status === 'AGUARDANDO AUDITORIA'"
                    class="mr-1 cursor-pointer"
                    size="36"
                    icon="EditIcon"
                    @click="editavel = true"
                  />
                </b-row>
              </b-col>
            </b-row>
            <b-row
              v-if="infoOrcamento.tipo_orcamento === 'AVULSO'"
              class="mb-1"
            >
              <b-col md="2">
                <label><strong>NOME</strong></label>
                <b-form-input
                  :value="infoOrcamento.nome ? infoOrcamento.nome : ''"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>FAVORECIDO</strong></label>
                <b-form-input
                  :value="infoOrcamento.nome_favorecido ? infoOrcamento.nome_favorecido : ''"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>CHAVE PIX</strong></label>
                <b-form-input
                  :value="infoOrcamento.chave_pix ? infoOrcamento.chave_pix : ''"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>AGENCIA</strong></label>
                <b-form-input
                  :value="infoOrcamento.agencia ? infoOrcamento.agencia : ''"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>CONTA</strong></label>
                <b-form-input
                  :value="infoOrcamento.conta ? infoOrcamento.conta : ''"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>DESCRIÇÃO</strong></label>
                <b-form-input
                  :value="infoOrcamento.descricao ? infoOrcamento.descricao : ''"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>VALOR SOLICITADO</strong></label>
                <b-row>
                  <b-col>
                    <b-form-input
                      v-model.lazy="infoOrcamento.valor_total_orcamento"
                      :disabled="!editavel"
                      @change="updateValorSolicitadoAvulso(infoOrcamento.valor_total_orcamento)"
                    />
                  </b-col>
                  <feather-icon
                    v-show="infoOrcamento.status === 'AGUARDANDO AUDITORIA'"
                    class="mr-1 cursor-pointer"
                    size="36"
                    icon="EditIcon"
                    @click="editavel = true"
                  />
                </b-row>
              </b-col>
            </b-row>

            <b-row class="mb-1">
              <b-col md="">
                <label for=""><strong>Atendente</strong></label>
                <b-form-input
                  :value="infoOrcamento.atendente"
                  disabled
                />
              </b-col>
            </b-row>

          </b-tab>
          <b-tab v-if="visualizarDadosModal">
            <template #title>
              <feather-icon icon="FileTextIcon" />
              <span>Prestador de Serviço</span>
            </template>

            <b-row class="mb-1">
              <b-col md="2">
                <label><strong>NOME</strong></label>
                <b-form-input
                  :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.nome : ''"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>CPF_CNPJ</strong></label>
                <b-form-input
                  :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.cpf_cnpj : ''"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>CHAVE PIX</strong></label>
                <b-form-input
                  :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.chave_pix : ''"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>BANCO</strong></label>
                <b-form-input
                  :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.banco : ''"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>AGENCIA</strong></label>
                <b-form-input
                  :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.agencia : ''"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>CONTA</strong></label>
                <b-form-input
                  :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.conta : ''"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>TELEFONE</strong></label>
                <b-form-input
                  :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.telefone : ''"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>EMAIL</strong></label>
                <b-form-input
                  :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.email : ''"
                  disabled
                />
              </b-col>
            </b-row>
          </b-tab>
          <b-tab v-if="visualizarDadosModal">
            <template #title>
              <feather-icon icon="FileTextIcon" />
              <span>Informações</span>
            </template>

            <b-row class="my-2">
              <b-col md="2">
                <label for=""><strong>Sinistro</strong></label>
                <b-form-input
                  :value="infoOrcamento.sinistro"
                  readonly
                />
              </b-col>
              <b-col md="5">
                <label for=""><strong>Cliente</strong></label>
                <b-form-input
                  :value="infoOrcamento.cliente"
                  readonly
                />
              </b-col>

              <b-col md="5">
                <label for=""><strong>Produto</strong></label>
                <b-form-input
                  :value="infoOrcamento.produto"
                  readonly
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <label for=""><strong>Relato do Cliente</strong></label>
                <b-form-input
                  :value="infoOrcamento.relato_cliente"
                  readonly
                />
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
        <template #modal-footer="{}">
          <b-overlay
            :show="load"
            variant="transparent"
          >
            <b-button
              v-show="infoOrcamento.status === 'AGUARDANDO AUDITORIA'"
              class="mr-1"
              size="md"
              variant="outline-warning"
              @click="openModalRevisarOrcamento"
            >
              Revisão
            </b-button>
            <b-button
              v-show="infoOrcamento.status === 'AGUARDANDO AUDITORIA'"
              class="mr-1"
              size="md"
              variant="outline-success"
              :disabled="!editavel"
              @click="aprovarOrcamento"
            >
              Aprovar
              <feather-icon
                class="plus-icon"
                size="16"
                icon="ThumbsUpIcon"
              />
            </b-button>
            <b-button
              v-show="infoOrcamento.status === 'AGUARDANDO AUDITORIA'"
              class="mr-1"
              size="md"
              variant="outline-danger"
              @click="openModalNegarOrcamento"
            >
              Negar
              <feather-icon
                class="color-icon"
                size="16"
                icon="ThumbsDownIcon"
              />
            </b-button>
            <b-button
              size="md"
              variant="outline-primary"
              @click="toViewOs(infoOrcamento.ordem_de_servico_id)"
            >
              Ir Para OS
              <feather-icon
                class="color-icon"
                size="16"
                icon="ExternalLinkIcon"
              />
            </b-button>
          </b-overlay>
        </template>
      </b-modal>
      <!-- modal visualizar info orcamento-->
      <!-- modal negar orcamento -->
      <b-modal
        ref="modalNegarOrcamento"
        title="Negar Orçamento"
        ok-title="Negar"
        cancel-title="Cancelar"
        cancel-variant="danger"
        :ok-disabled="motivoNegarOrcamento === ''"
        @ok="negarOrcamento"
      >
        <b-form-group>
          <b-form-textarea v-model="motivoNegarOrcamento" />
        </b-form-group>
      </b-modal>
      <!-- modal negar orcamento -->
      <b-modal
        ref="modalRevisarOrcamento"
        title="Revisar Orçamento"
        ok-title="Revisar"
        cancel-title="Cancelar"
        cancel-variant="danger"
        :ok-disabled="motivoRevisaoOrcamento === ''"
        @ok="revisarOrcamento"
      >
        <b-form-group>
          <b-form-textarea v-model="motivoRevisaoOrcamento" />
        </b-form-group>
      </b-modal>
      <div class="m-2">

        <!-- Table Top -->
        <b-row
          style="gap: 5px;"
        >
          <!-- Search -->
          <b-col>
            <b-form-select
              v-model="perPage"
              style="width: 70px; height: 32px; margin-top: 12px;"
              label="text"
              :options="[10, 25, 50, 100]"
              size="sm"
            />
          </b-col>
          <b-col>
            <b-form-input
              v-model="searchQuery"
              style="width: 850px; height: 32px; margin-top: 12px;"
              class="d-inline-block mr-1"
              placeholder="Pesquisar..."
              :options="[10, 15, 20]"
              type="search"
              size="sm"
              width="100%"
            />
          </b-col>
          <b-col>
            <b-button
              v-show="listFiltered.length > 0"
              style="width: 150px; height: 45px;"
              variant="outline-warning"
              @click="exportFile"
            >
              Exportar excel
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-col>
        <b-overlay
          variant="white"
          :show="loading"
          spinner-variant="primary"
          blur="0"
          opacity=".75"
          rounded="sm"
        >
          <b-row>
            <b-col>
              <b-table
                striped
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="listFiltered"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
              >
                <template #cell(status)="data">
                  <b-badge :variant="resolveBadgeVariantStatusOrcamento(data.item.status)">
                    {{ data.item.status }}
                  </b-badge>
                </template>
                <template #cell(created_at)="data">
                  {{ dataHora(data.item.created_at, true) }}
                </template>
                <!-- eslint-disable-next-line -->
                  <template #cell(data_abertura)="data">
                  {{ data.item.data_abertura ? formatTimezone(data.item.data_abertura, true) : '' }}
                </template>
                <template #cell(data_auditacao)="data">
                  {{ data.item.data_auditacao ? formatTimezone(data.item.data_auditacao, true) : '' }}
                </template>
                <template #cell(valor_total_orcamento)="data">
                  R$ {{ valorBr(data.item.valor_total_orcamento, true) }}
                </template>
                <template #cell(valor_total_aprovado)="data">
                  R$ {{ data.item.valor_total_aprovado ? valorBr(data.item.valor_total_aprovado, true) : '' }}
                </template>
                <template #cell(acoes)="data">
                  <b-row>
                    <b-col>
                      <feather-icon
                        v-b-tooltip.hover.top="'Detalhes'"
                        class="color-icon"
                        size="16"
                        icon="EyeIcon"
                        @click="openModalOrcamento(data.item)"
                      />
                    </b-col>
                    <b-col>
                      <feather-icon
                        v-b-tooltip.hover.top="'Ir Para OS'"
                        class="color-icon"
                        size="16"
                        icon="ExternalLinkIcon"
                        @click="toViewOs(data.item.ordem_de_servico_id)"
                      />
                    </b-col>

                  </b-row>
                </template>
              </b-table>
            </b-col>

            <b-col
              cols="12"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="items.length"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-overlay>
      </b-col>
    </b-row>
  </b-card>

</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BPagination,
  BModal,
  BBadge,
  VBTooltip,
  BCard,
  BTabs,
  BTab,
  BFormInput,
  BButton,
  BFormGroup,
  BFormTextarea,
  BFormSelect,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { saveAs } from 'file-saver'
import { mapState } from 'vuex'

import { Money, VMoney } from 'v-money'

import axios from '../../../../../axios-auth'

const ExcelJS = require('exceljs')

export default {
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BPagination,
    BModal,
    BBadge,
    BTabs,
    BTab,
    BFormInput,
    BButton,
    BFormGroup,
    BFormTextarea,
    BFormSelect,
    vSelect,
    BOverlay,

    Money,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
    money: VMoney,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    updateGrid: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false,
      },
      searchQuery: '',
      optionsTipoServico: [
        'VISITA',
        'MÃO DE OBRA',
        'PEÇAS',
        'AVULSO',
        'INTERNET',
        'OUTROS',
      ],
      servicoSelected: [],
      perPage: 50,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'status', label: 'Status', sortable: true, class: 'text-center' },
        { key: 'data_abertura', label: 'DATA ABERTURA OS', sortable: true, class: 'text-center' },
        { key: 'created_at', label: 'Data', sortable: true, class: 'text-center' },
        { key: 'dias_vencidos',
          label: 'QDA',
          class: 'text-center',
          sortable: true,
          formatter(value, key, item) {
            const dataAtual = new Date()
            const dataAbertura = new Date(item.data_abertura)
            const diferencaEmDias = Math.floor((dataAtual - dataAbertura) / (1000 * 60 * 60 * 24))
            return diferencaEmDias < 0 ? 'Em andamento' : `${diferencaEmDias}`
          },
        },
        { key: 'tipo_atendimento', label: 'Tipo de Atendimento', sortable: true, class: 'text-center' },
        { key: 'sinistro', label: 'Sinistro', sortable: true, class: 'text-center' },
        { key: 'seguradora', label: 'SEGURADORA', sortable: true, class: 'text-center' },
        { key: 'is', label: 'IS', sortable: true, class: 'text-center' },
        { key: 'valor_total_orcamento', label: 'Valor Solicitado', sortable: true, class: 'text-center' },
        { key: 'valor_total_aprovado', label: 'Valor Aprovado', sortable: true, class: 'text-center' },
        { key: 'data_auditacao', label: 'Data Aprovação', sortable: true, class: 'text-center' },
        { key: 'acoes', label: 'Ações', class: 'text-center' },
      ],
      infoOrcamento: {},
      visualizarDadosModal: false,
      editavel: false,
      valorServicos: {},
      newValorServico: 0,
      newServicos: [],
      motivoNegarOrcamento: '',
      observacao: '',
      newValorAvulso: 0,
      tipo_servico: '',
      statusFilter: [],
      atendenteFilter: [],
      seguradoraFilter: [],
      marcaFilter: [],
      tipoAtendimentoFilter: [],
      motivoRevisaoOrcamento: '',
      load: false,
    }
  },
  computed: {
    ...mapState({
      items: state => state.orcamento.items,
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    optionsStatus() {
      return [...new Set(this.items.map(item => item.status))]
    },
    optionsSeguradora() {
      const uniqueSeguradoras = new Set()
      this.items.forEach(item => {
        if (item.seguradora) {
          uniqueSeguradoras.add(item.seguradora)
        }
      })
      return [...uniqueSeguradoras]
    },
    optionsMarca() {
      const uniqueMarca = new Set()
      this.items.forEach(item => {
        if (item.marca) {
          uniqueMarca.add(item.marca)
        }
      })
      return [...uniqueMarca]
    },
    optionsAtendenteFilter() {
      return [...new Set(this.items.map(item => item.atendente))]
    },
    optionsTipoAtendimento() {
      return [...new Set(this.items.map(item => item.tipo_atendimento))]
    },
    listFiltered() {
      const { items, statusFilter: sF, tipoAtendimentoFilter: tF, atendenteFilter: aF, seguradoraFilter: seguF, marcaFilter: mF } = this
      let list = items

      const filterQueryList = {
        sF: {
          active: false,
        },
        tF: {
          active: false,
        },
        aF: {
          active: false,
        },
        seguF: {
          active: false,
        },
        mF: {
          active: false,
        },
      }

      if (sF.length > 0) {
        filterQueryList.sF.active = true
      }

      if (tF.length > 0) {
        filterQueryList.tF.active = true
      }

      if (aF.length > 0) {
        filterQueryList.aF.active = true
      }
      if (seguF.length > 0) {
        filterQueryList.seguF.active = true
      }
      if (mF.length > 0) {
        filterQueryList.mF.active = true
      }

      list = list.filter(item => {
        let filterCheck = null
        Object.keys(filterQueryList).forEach(key => {
          if (filterQueryList[key].active) {
            if (filterCheck === null) {
              filterCheck = true
            }
            switch (key) {
              case 'seguF': {
                if (item.seguradora) {
                  filterCheck = filterCheck && seguF.includes(item.seguradora)
                }
                break
              }
              case 'sF':
                filterCheck = filterCheck && sF.includes(item.status)
                break
              case 'tF':
                filterCheck = filterCheck && tF.includes(item.tipo_atendimento)
                break
              case 'aF':
                filterCheck = filterCheck && aF.includes(item.atendente)
                break
              case 'mF':
                if (item.marca) {
                  filterCheck = mF.includes(item.marca)
                }
                break
              default:
                filterCheck = null
                break
            }
          }
        })

        if (this.avaibleSearchQuery) {
          filterCheck = filterCheck || item.sinistro.includes(this.searchQuery)
        }

        if (filterCheck === null) {
          return true
        }

        return filterCheck
      })
      this.$store.commit('SET_FILTERED_ITEMS', list)

      this.totalRows = list.length
      return list
    },
    avaibleSearchQuery() {
      const { searchQuery } = this

      if (searchQuery.length >= 3) {
        return true
      }

      return false
    },
  },
  methods: {
    async exportFile() {
      const file = await this.getDataToExported()
      if (file) {
        saveAs(file)
      }
    },

    async getDataToExported() {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Relatório Auditor')
      const itemsPlan = this.listFiltered

      worksheet.columns = [
        { header: 'ID', key: 'id' },
        { header: 'NOME', key: 'nome' },
        { header: 'STATUS', key: 'status' },
        { header: 'DATA ABERTURA OS', key: 'data_abertura' },
        { header: 'DATA', key: 'created_at' },
        { header: 'QDA', key: 'dias_vencidos' },
        { header: 'TIPO ATENDIMENTO', key: 'tipo_atendimento' },
        { header: 'SINISTRO', key: 'sinistro' },
        { header: 'SEGURADORA', key: 'produto.seguradora.nome' },
        { header: 'D. APROVAÇÃO', key: 'data_auditacao' },
        { header: 'V. TOTAL ORÇAMENTO', key: 'valor_total_orcamento' },
        { header: 'V. TOTAL APROVADO', key: 'valor_total_aprovado' },
      ]

      const wk1Columns = [
        'valor_total_orcamento',
        'valor_total_aprovado',
      ]

      const totais = {
        wk1: {},
      }

      itemsPlan.map(item => {
        worksheet.addRow([
          item.id,
          item.nome,
          item.status,
          item.data_abertura ? this.formatTimezone(item.data_abertura) : null,
          item.created_at ? this.dataHora(item.created_at) : null,
          item.dias_vencidos,
          item.tipo_atendimento,
          item.sinistro,
          item.seguradora,
          item.data_auditacao ? this.formatTimezone(item.data_auditacao) : null,
          item.valor_total_orcamento ? this.valorBr(parseFloat(item.valor_total_orcamento)) : null,
          item.valor_total_aprovado ? this.valorBr(parseFloat(item.valor_total_aprovado)) : null,
        ])
        wk1Columns.map(w => {
          if (item[w] !== null) {
            totais.wk1[w] ? totais.wk1[w] += parseFloat(item[w]) : totais.wk1[w] = parseFloat(item[w])
          }
        })
      })

      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'FFFFFF' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })
      const totalRowWk1 = worksheet.addRow({
        valor_total_orcamento: totais.wk1.valor_total_orcamento,
        valor_total_aprovado: totais.wk1.valor_total_aprovado,
      })
      totalRowWk1.font = {
        name: 'Calibri',
        family: 2,
        bold: true,
        size: 11,
      }
      totalRowWk1.eachCell({ includeEmpty: false }, cell => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'F4A460' },
        }
        cell.alignment = {
          horizontal: 'center',
          vertical: 'center',
        }
      })
      wk1Columns.map(key => {
        worksheet.getColumn(`${key}`).numFmt = '"R$"#,##0.00;[Red]-"R$"#,##0.00'
      })

      worksheet.columns.forEach(column => {
        let dataMax = 0
        column.eachCell({ includeEmpty: true }, cell => {
          const columnLength = cell.value ? cell.value.length : 15
          if (columnLength > dataMax) { dataMax = columnLength + 5 }
        })
        column.width = dataMax < 15 ? 15 : dataMax
      })

      let blob = null

      await workbook.xlsx.writeBuffer().then(data => {
        // eslint-disable-next-line camelcase
        blob = new File([data], 'Relatório Auditor', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      })
      return blob
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    openModalOrcamento(item) {
      this.infoOrcamento = item
      this.visualizarDadosModal = true
      this.$refs.showModalOrcamento.show()
    },
    updateValorSolicitado(valor, index) {
      this.valorServicos[index] = valor
      let sum = 0
      Object.keys(this.valorServicos).map(key => {
        sum += this.valorServicos[key]
      })
      this.newValorServico = sum
    },
    updateValorSolicitadoAvulso(valor) {
      const valorFormatado = Number(valor.replace(',', '.'))
      this.newValorAvulso += valorFormatado
    },
    updateObservacao(observacao) {
      this.observacao = ''
      this.observacao = observacao
    },
    updateTipoServico(tipoServico) {
      this.tipo_servico = ''
      this.tipo_servico = tipoServico
    },
    aprovarOrcamento() {
      this.$swal({
        title: 'ATENÇÃO',
        text: 'Deseja aprovar esse orçamento?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, confirmo!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.load = true
          const body = {
            orcamento: {
              orcamento_id: this.infoOrcamento.orcamento_id,
              status: 'AUDITADO',
              auditor_id: parseInt(localStorage.getItem('userId')),
            },
          }
          if (this.newValorServico !== 0) {
            body.orcamento.valor_total_aprovado = this.newValorServico

            // this.newServicos.push({
            //   valor_aprovado: valor,
            //   servico_id: item.id,
            //   observacao: this.observacao,
            //   tipo_servico: this.tipo_servico,
            // })
            this.infoOrcamento.servicos.map((servico, index) => {
              this.newServicos.push({
                valor_aprovado: this.valorServicos[index],
                servico_id: servico.id,
                observacao: this.observacao,
                tipo_servico: this.tipo_servico,
              })
            })

            body.servicos = this.newServicos
          } else {
            body.orcamento.valor_total_aprovado = this.newValorAvulso
          }
          axios
            .post('api/orcamento/aprovar', body)
            .then(() => {
              this.$refs.showModalOrcamento.hide()
              this.updateGrid()
              this.load = false
              this.clearAcoesOrcamento()
              this.$swal({
                title: 'SUCESSO!',
                icon: 'success',
                text: 'ORÇAMENTO APROVADO!',
                timer: 1500,
                showConfirmButton: false,
              })
            })
            .catch(error => {
              this.load = false
              this.clearAcoesOrcamento()
              if (error.response) {
                this.$swal({
                  title: 'ERRO!',
                  icon: 'error',
                  text: `Ocorreu um erro: ${error.response.data.mensagem} `,
                  customClass: {
                    confimButton: 'btn btn-danger',
                  },
                })
              }
            })
        }
      })
    },
    clearAcoesOrcamento() {
      this.valorServicos = {}
      this.newValorServico = 0
      this.newServicos = []
      this.motivoNegarOrcamento = ''
      this.newValorAvulso = 0
      this.motivoRevisaoOrcamento = ''
    },
    quickAprove(item) {
      this.infoOrcamento = item
      this.aprovarOrcamento()
    },
    quickNegative(item) {
      this.infoOrcamento = item
      this.openModalNegarOrcamento()
    },
    toViewOs(id) {
      this.$router.push({ name: 'os-view', params: { id } })
    },
    openModalNegarOrcamento() {
      this.$swal({
        title: 'ATENÇÃO',
        text: 'Deseja negar esse orçamento?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, confirmo!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$refs.modalNegarOrcamento.show()
        }
      })
    },
    negarOrcamento() {
      const body = {
        orcamento_id: this.infoOrcamento.orcamento_id,
        descricao: this.motivoNegarOrcamento.toUpperCase(),
        status: 'NEGADO',
        auditor_id: parseInt(localStorage.getItem('userId')),
      }
      axios
        .post('api/orcamento/negar', body)
        .then(() => {
          this.$refs.showModalOrcamento.hide()
          this.updateGrid()
          this.clearAcoesOrcamento()
          this.$swal({
            title: 'SUCESSO!',
            icon: 'success',
            text: 'ORÇAMENTO NEGADO!',
            timer: 1500,
            showConfirmButton: false,
          })
        })
        .catch(error => {
          this.$refs.showModalOrcamento.hide()
          this.updateGrid()
          this.clearAcoesOrcamento()
          if (error.response) {
            this.$swal({
              title: 'ERRO!',
              icon: 'error',
              text: `Ocorreu um erro: ${error.response.data.mensagem} `,
              customClass: {
                confimButton: 'btn btn-danger',
              },
            })
          }
        })
    },
    openModalRevisarOrcamento() {
      this.$swal({
        title: 'ATENÇÃO',
        text: 'Deseja revisar esse orçamento?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, confirmo!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$refs.modalRevisarOrcamento.show()
        }
      })
    },
    revisarOrcamento() {
      const body = {
        orcamento_id: this.infoOrcamento.orcamento_id,
        motivo: this.motivoRevisaoOrcamento.toUpperCase(),
        tipo_revisao: 'NORMAL',
      }
      axios
        .post('api/orcamento/revisar', body)
        .then(() => {
          this.$refs.modalRevisarOrcamento.hide()
          this.updateGrid()
          this.clearAcoesOrcamento()
          this.$swal({
            title: 'SUCESSO!',
            icon: 'success',
            text: 'ORÇAMENTO ENVIADO PARA REVISÃO!',
            timer: 1500,
            showConfirmButton: false,
          })
        })
        .catch(error => {
          this.$refs.modalRevisarOrcamento.hide()
          this.updateGrid()
          this.clearAcoesOrcamento()
          if (error.response) {
            this.$swal({
              title: 'ERRO!',
              icon: 'error',
              text: `Ocorreu um erro: ${error.response.data.mensagem} `,
              customClass: {
                confimButton: 'btn btn-danger',
              },
            })
          }
        })
    },

    removeCurrencyMask(valor) {
      const withwoutMark = valor.replace('R$', '')

      const toFloat = withwoutMark.replace('.', '').replace(',', '.')

      return Number(toFloat) || 0
    },

    setStatusFilterByCard(status) {
      if (!this.statusFilter.includes(status)) this.statusFilter = [status]
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.nova-entidade {
  float: right;
  margin-right: 5px;
}

.nova-entidade:hover {
  color: blueviolet;
  cursor: pointer;
}
.per-page-selector {
  width: 90px;
}
</style>
